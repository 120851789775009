

import PDEIcon from '@/components/pde/PDEIcon.vue'

export default {
  name: 'PDEToast',
  components: {
    PDEIcon,
  },
  props: {
    keyVal: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'success',
      validator: value => ['error', 'success', 'busy', 'info'].includes(value),
    },
    xPosition: {
      type: String,
      default: 'center',
      validator: value => ['left', 'right', 'center'].includes(value),
    },
    yPosition: {
      type: String,
      default: 'top',
      validator: value => ['top', 'bottom', 'middle'].includes(value),
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['closeToast'],

  computed: {
    positionClasses() {
      const xPositions = {
        left: 'justify-start',
        center: 'justify-center',
        right: 'justify-end',
      }

      const yPositions = {
        top: 'items-start',
        middle: 'items-center',
        bottom: 'items-end',
      }

      return `${xPositions[this.xPosition]} ${yPositions[this.yPosition]}`
    },

    bgClass() {
      const classes = {
        success: 'bg-success-100',
        error: 'bg-error-100',
        busy: 'bg-success-100',
        info: 'bg-highlight-50',
      }

      return classes[this.type]
    },

    icon() {
      return {
        success: 'checkmark',
        info: 'error-circle',
      }[this.type] || ''
    },

    iconClass() {
      return {
        info: 'text-highlight-800',
      }[this.type] || ''
    },

    textClass() {
      const classes = {
        success: 'text-success-900',
        error: 'text-black-800',
        busy: 'text-success-900',
        info: 'text-black-700',
      }

      return classes[this.type]
    },
  },
}
