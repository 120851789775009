
import { mapActions, mapGetters } from 'vuex'
import PDEDialog from '@/components/pde/PDEDialog.vue'
import PDEButton from '@/components/pde/PDEButton.vue'
export default {
  components: {
    PDEDialog,
    PDEButton,
  },

  computed: {
    ...mapGetters('navigation', ['availableToWorkModalOpen']),
  },

  methods: {
    ...mapActions('account', [
      'setAvailableToWork',
    ]),
    ...mapActions('navigation', [
      'toggleAvailableToWorkModal',
    ]),

    cancelAvailableToWorkOff() {
      this.toggleAvailableToWorkModal()
    },

    confirmAvailableToWorkOff() {
      this.setAvailableToWork({ availableToWork: false })
      this.toggleAvailableToWorkModal()
    },

  },
}
