import { ref, onMounted, onUnmounted } from 'vue'

const isMobileCheck = () => window.innerWidth < 768

export const useIsMobile = () => {
  const isMobile = ref(isMobileCheck())

  const handler = () => {
    isMobile.value = isMobileCheck()
  }

  onMounted(() => window.addEventListener('resize', handler))
  onUnmounted(() => window.removeEventListener('resize', handler))

  return isMobile
}
