
import { mapActions, mapGetters, mapState } from 'vuex'
import PDEButton from '@/components/pde/PDEButton.vue'
import PDEDialog from '@/components/pde/PDEDialog.vue'

export default {
  components: {
    PDEDialog,
    PDEButton,
  },

  computed: {
    ...mapGetters('account', ['availableToWork', 'getFormalName']),
    ...mapGetters('analytics', ['getWebTracking']),
    ...mapState('identity', ['availableToWorkDialogDismissed']),
    ...mapState('account', ['userDetailsLoaded']),

    isVisible() {
      const webTrackingSource = this.getWebTracking('source')
      const webTrackingSourceValue = webTrackingSource?.value
      const webTrackingSourceType = webTrackingSource?.type

      // Campaigns for the Available to Work feature are set in LaunchDarkly
      const launchDarklyCampaign = this.$featureFlags?.availableToWorkCampaigns100?.[this.$globalVars.brand]?.find(campaign => campaign.id === webTrackingSourceValue)

      return !!webTrackingSourceValue &&
        !this.availableToWork &&
        !this.availableToWorkDialogDismissed &&
        webTrackingSourceType === 'query' &&
        this.userDetailsLoaded &&
        !!launchDarklyCampaign
    },

    bodyText() {
      const webTrackingSource = this.getWebTracking('source')
      const webTrackingSourceValue = webTrackingSource?.value

      return webTrackingSourceValue &&
        this.$featureFlags?.availableToWorkCampaigns100?.[this.$globalVars.brand]?.find(campaign => campaign.id === webTrackingSourceValue)?.text
    },

    headingText() {
      return `Welcome back, ${this.getFormalName}`
    },
  },

  mounted() {
    if (this.availableToWork) {
      this.setAvailableToWorkDialogDismissed(true)
    }
  },

  methods: {
    ...mapActions('account', ['setAvailableToWork']),
    ...mapActions('identity', ['setAvailableToWorkDialogDismissed']),

    close() {
      this.setAvailableToWorkDialogDismissed(true)
    },

    confirm() {
      this.setAvailableToWork({ availableToWork: true, comments: 'This is from the win back campaign' })
      this.close()
    },
  },
}
