
import { mapActions, mapGetters } from 'vuex'
import PDEDrawer from '@/components/pde/PDEDrawer.vue'
import PDEButton from '@/components/pde/PDEButton.vue'
import PDEIcon from '@/components/pde/PDEIcon.vue'

export default {
  components: {
    PDEDrawer,
    PDEButton,
    PDEIcon,
  },

  data() {
    return {
      subscriptionUrls: {
        google: 'https://calendar.google.com/calendar/u/0/r?cid=',
        apple: '',
      },
    }
  },

  computed: {
    ...mapGetters('assignment', ['calendarSyncDrawerOpen']),
    ...mapGetters('account', ['getContactId']),
    ...mapGetters('identity', ['providerRepId']),
  },

  methods: {
    ...mapActions('assignment', ['toggleCalendarSyncWithDrawer']),

    handleDrawerClick() {
      if (this.calendarSyncDrawerOpen) {
        this.toggleCalendarSyncWithDrawer()
      }
    },

    subscribe(calendarType) {
      const providerInfoObj = {
        providerId: this.getContactId,
        providerRepId: this.providerRepId,
      }
      const providerInfoStr = JSON.stringify(providerInfoObj)
      const providerInfoEncoded = Buffer.from(providerInfoStr, 'utf-8').toString('base64')
      const calendarUrl = calendarType !== 'google' ? `${this.subscriptionUrls[calendarType]}${this.$globalVars.calendarSubscriptionMobileUrl}/calendar-subscription/${providerInfoEncoded}` : `${this.subscriptionUrls[calendarType]}${this.$globalVars.calendarSubscriptionUrl}/calendar-subscription/${providerInfoEncoded}?ds=${Date.now()}`

      if (calendarType === 'google') {
        window.open(calendarUrl, '_blank')
      } else {
        window.location.assign(calendarUrl)
      }
    },
  },
}
