
import PDEIcon from '@/components/pde/PDEIcon.vue'
import PDEHeading from '@/components/pde/PDEHeading.vue'

export default {
  name: 'PDEMessageBox',

  components: {
    PDEIcon,
    PDEHeading,
  },

  props: {
    backgroundColor: {
      type: String,
      default: 'bg-pureblack-0',
    },

    heading: {
      type: String,
      default: '',
    },

    icon: {
      type: String,
      default: 'tools-fill',
    },

    message: {
      type: String,
      default: '',
    },

    padding: {
      type: String,
      default: 'p-24 xs:p-40',
    },

    showIcon: {
      type: Boolean,
      default: true,
    },

    textAlignment: {
      type: String,
      default: 'center',
      validator: value => ['left', 'center', 'right'].includes(value),
    },
  },

  computed: {
    textAlignmentClass() {
      return {
        left: 'text-left',
        center: 'text-center',
        right: 'text-right',
      }[this.textAlignment]
    },
  },
}
