
import { nextTick } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import PDEButton from '@/components/pde/PDEButton.vue'
import LogRocket from 'logrocket'

export default {
  components: {
    PDEButton,
  },
  props: {
    showTitle: {
      type: Boolean,
      default: false,
    },
  },

  data: () => {
    return {
      leadComment: '',
      isLoading: false,
    }
  },

  computed: {
    ...mapGetters('jobs', ['sendJobToRepModalDetails']),
    ...mapGetters('identity', ['loggedInAsRep']),
    ...mapGetters('account', ['isPartialUser']),
  },

  methods: {
    ...mapActions('navigation', ['toggleSendToRepModal']),
    ...mapActions('jobs', [
      'resetSendJobToRepModalDetails',
      'sendLeadRequest',
      'setJobAsSentToRep',
    ]),
    ...mapActions('analytics', ['sendClickEvent']),

    handleConfirmInterestClick() {
      this.sendClickEvent({
        name: 'Confirm Interest Click',
        text: 'Send',
        area: 'action-pane/new-jobs',
      })
    },

    async  scrollToInput() {
      await nextTick()
      window.scrollTo(0, document.querySelector('#send-to-rep').offsetTop + 50)
    },

    updateComment(e) {
      this.leadComment = e.target.value
    },

    async sendToRepAndTrack() {
      // Temp LogRocket tracking for Confirm Interest Click
      if (this.$featureFlags.logRocketSendInterest) {
        LogRocket.track('Confirm Interest Click')
      }
      if (this.isLoading) return
      this.isLoading = true
      this.handleConfirmInterestClick()
      if (!this.leadComment) this.leadComment = "I'm interested..."
      try {
        await this.sendLeadRequest({ job: this.sendJobToRepModalDetails, leadComment: this.leadComment })
        this.setJobAsSentToRep(this.sendJobToRepModalDetails.id)
      } catch (err) {
        LogRocket.captureException(err)
      } finally {
        this.toggleSendToRepModal(false)
        this.isLoading = false
      }
      setTimeout(() => {
        this.resetSendJobToRepModalDetails()
        this.leadComment = ''
      }, 500)
    },
  },
}
