import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "general-contacts" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MyTeamCard = _resolveComponent("MyTeamCard")
  const _component_MyTeamCards = _resolveComponent("MyTeamCards")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MyTeamCard, {
      id: "providerRep",
      contact: _ctx.providerRep,
      "analytics-data": {
        clickName: 'Provider Contact Click',
        areaType: 'nav',
        clickArea: 'nav/my-team',
      },
      "hide-texting": false,
      size: "small"
    }, null, 8, ["contact"]),
    _createVNode(_component_MyTeamCards, {
      contacts: $options.contacts,
      "analytics-data": {
        clickName: 'Provider Contact Click',
        areaType: 'nav',
        clickArea: 'nav/my-team'
      },
      size: "small"
    }, null, 8, ["contacts"]),
    ($options.emergencyTravelCheck === true)
      ? (_openBlock(), _createBlock(_component_MyTeamCard, {
          key: 0,
          id: "emergencyContact",
          contact: $options.emergencyTravel,
          "analytics-data": {
        clickName: 'Provider Contact Click',
        areaType: 'nav',
        clickArea: 'nav/my-team'
      },
          size: "small"
        }, null, 8, ["contact"]))
      : _createCommentVNode("", true)
  ]))
}