
import PDEDrawer from '@/components/pde/PDEDrawer.vue'
import { mapActions, mapGetters } from 'vuex'
import GeneralContacts from '@/components/account/Dashboard/Assignments/GeneralContacts'
import PDEAccordion from '@/components/pde/PDEAccordion.vue'
import MyTeamCards from '@/components/account/Dashboard/Assignments/Cards/MyTeamCards'
import isEqual from 'lodash/isEqual'

export default {
  components: {
    PDEDrawer,
    GeneralContacts,
    PDEAccordion,
    MyTeamCards,
  },

  computed: {
    ...mapGetters('assignment', [
      'myTeamsDrawerOpen',
      'getMyTeamDetailsByAssignmentId',
      'confirmed',
    ]),

    getTeamName() {
      return `My ${this.$t('global.brandTitle')} Team`
    },

    filteredConfirmed() {
      return this.removeDuplicates(this.confirmed)
    },
  },

  watch: {
    confirmed() {
      this.loadMyTeamDetails()
    },

    filteredConfirmed(newValue) {
      this.openFirstAssignmentAccordion()
    },
  },

  methods: {
    ...mapActions('assignment', [
      'toggleMyTeamsDrawer',
      'setMyTeamDetailsByAssignment',
      'loadMyTeamDetails',
    ]),

    handleAccordionAction(state, assignmentId) {
      if (state.isOpen) {
        this.setMyTeamDetailsByAssignment({ assignmentId })
      }
    },

    clickOutside($event) {
      if (!$event.target.closest('#myteams-button') && !$event.target.closest('#work-preferences-rep-link') && this.myTeamsDrawerOpen) {
        this.toggleMyTeamsDrawer({ close: true })
      }
    },

    handleDrawerClick() {
      if (this.myTeamsDrawerOpen) {
        this.toggleMyTeamsDrawer()
      }
    },

    async openFirstAssignmentAccordion() {
      await this.$nextTick()

      const firstAssignmentAccordion = this.$refs['PDE-Accordion-Ref-0']

      if (!firstAssignmentAccordion) return

      const allStatus = Object.entries(this.$refs).every(
        ([key, value]) => value[0].open === false
      )

      if (allStatus) {
        firstAssignmentAccordion[0].toggleClick()
      }
    },

    showHideBody(index) {
      const assignmentAccordion = this.$refs[`PDE-Accordion-Ref-${index}`]

      if (!assignmentAccordion) return
      assignmentAccordion[0].toggleClick()
    },

    removeDuplicates(array) {
      if (!array) return []

      const seenItems = {}

      return array.filter((item) => {
        const itemContactDetails = this.getMyTeamDetailsByAssignmentId(item.assignmentId, this.$featureFlags)

        if (!itemContactDetails?.length || (seenItems[item?.worksiteId] && seenItems[item.worksiteId].some((el) => isEqual(el, itemContactDetails)))) {
          return false
        }
        seenItems[item.worksiteId] = seenItems[item.worksiteId] || []
        seenItems[item.worksiteId].push(itemContactDetails)

        return true
      })
    },
  },
}
