
import crypto from 'crypto'
import { mapActions } from 'vuex'
import PDEBanner from '@/components/pde/PDEBanner.vue'

export default {
  components: {
    PDEBanner,
  },

  props: {
    bannerProps: {
      type: Object,
      default: () => ({}),
    },
  },

  emits: ['on-close'],

  data() {
    return {
      collapsed: true,
      showToggle: false,
    }
  },

  computed: {
    bannerId() {
      if (this.bannerProps.bannerId) return this.bannerProps.bannerId
      return `global-${crypto.createHash('sha256').update(this.$featureFlags.globalBannerMessage).digest('hex')}`
    },

    isVisible() {
      return !!this.$refs.banner?.isVisible
    },
  },

  mounted() {
    this.initialize()
  },

  beforeUnmount() {
    this.cleanup()
  },

  methods: {
    ...mapActions('banners', ['setGlobalBannerHeight']),

    cleanup() {
      window.removeEventListener('resize', this.onResize)
      this.setGlobalBannerHeight(0)
    },

    initialize() {
      if (!this.isVisible) return

      window.addEventListener('resize', this.onResize)
      this.onResize()
    },

    onClose() {
      this.cleanup()
      this.$emit('on-close')
    },

    onResize() {
      if (!this.isVisible) {
        this.cleanup()
        return
      }

      if (!this.collapsed) this.collapsed = true

      this.$nextTick(() => {
        this.setGlobalBannerHeight(this.$el.clientHeight)
        this.showToggle = this.$refs.bannerText.scrollHeight > this.$refs.bannerText.clientHeight
      })
    },

    onToggleClick() {
      this.collapsed = !this.collapsed
      this.$nextTick(() => this.setGlobalBannerHeight(this.$el.clientHeight))
    },
  },
}
