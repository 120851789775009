
import { mapActions, mapGetters } from 'vuex'
import PDEIcon from '@/components/pde/PDEIcon.vue'
import PDEIconButton from '@/components/pde/PDEIconButton.vue'

export default {
  name: 'PDEBanner',

  components: {
    PDEIcon,
    PDEIconButton,
  },

  props: {
    bannerId: {
      type: String,
      required: true,
    },

    centered: {
      type: Boolean,
      default: false,
    },

    closeIcon: {
      type: String,
      default: 'close',
    },

    heightClasses: {
      type: String,
      default: 'min-h-48',
    },

    icon: {
      type: String,
      default: '',
    },

    showCloseButton: {
      type: Boolean,
      default: true,
    },

    showIcon: {
      type: Boolean,
      default: true,
    },

    type: {
      type: String,
      default: 'success',
      validator: value =>
        ['success', 'info', 'warning', 'error'].indexOf(value) !== -1,
    },
  },
  emits: ['on-close'],

  computed: {
    ...mapGetters('banners', ['wasClosed']),

    bgColorClass() {
      return {
        success: 'bg-success-100',
        info: 'bg-highlight-100',
        warning: 'bg-warning-100',
        error: 'bg-error-100',
      }[this.type]
    },

    iconColorClass() {
      return {
        success: 'text-success-700',
        info: 'text-highlight-700',
        warning: 'text-warning-700',
        error: 'text-error-700',
      }[this.type]
    },

    iconName() {
      if (this.icon) return this.icon

      return {
        error: 'warning-fill',
        info: 'plus',
        success: 'checkmark',
        warning: 'warning-fill',
      }[this.type]
    },

    isVisible() {
      return !this.wasClosed(this.bannerId)
    },
  },

  mounted() {
    this.updateUi(this.bannerId)
  },

  methods: {
    ...mapActions('banners', ['hide', 'updateUi']),

    close() {
      this.hide(this.bannerId)
      this.$emit('on-close')
    },
  },
}
